<template>
  <div class="addmedia">
    <div class="select">
      <div class="id">{{ media.id }}</div>
      <el-radio-group v-model="media.type" size="small">
        <el-radio-button label="3">照片</el-radio-button>
        <el-radio-button label="4">视频</el-radio-button>
        <el-radio-button label="5">音频</el-radio-button>
        <el-radio-button label="6">文件</el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="media.id.length > 0" class="title">
      <el-input
        style="font-size:16px"
        type="textarea"
        autosize
        placeholder="标题"
        v-model="media.title"
        maxlength="300"
        show-word-limit
      >
      </el-input>
    </div>
    <div v-if="media.id.length > 0" class="tag">
      <el-checkbox-group v-model="tagselect" size="mini">
        <el-checkbox v-for="i in taglist" :key="i.ID" :label="i.ID">{{
          i.title
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-if="media.id.length > 0" class="summary">
      <el-input
        style="font-size:16px"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 6 }"
        placeholder="简介"
        v-model="media.summary"
        maxlength="300"
        show-word-limit
      >
      </el-input>
    </div>
    <div v-if="media.id.length > 0" class="upload">
      <div v-if="media.type == 3" class="imglist">
        <div class="imgdiv" v-for="i in filelist" :key="i.ID">
          <el-image
            fit="scale-down"
            style="width: 200px; height: 200px;"
            :preview-src-list="[i.links]"
            :src="i.links + '!h2'"
          ></el-image>
          <el-link
            style="font-size: 15px;margin-top:-4px;"
            icon="el-icon-delete"
            @click="delFile(i.ID)"
          ></el-link>
        </div>
      </div>
      <div v-else class="filelist">
        <div style="margin:0px 0 7px;" v-for="i in filelist" :key="i.ID">
          <el-link
            style="font-size: 15px;margin:-4px 10px 0px 0px"
            icon="el-icon-delete"
            @click="delFile(i.ID)"
          ></el-link>
          <el-link
            target="_blank"
            :href="i.links"
            style="font-size: 15px;margin-top:-4px;"
          >
            {{ i.links }}
          </el-link>
        </div>
      </div>
      <el-upload
        :action="url"
        :on-success="loadfile"
        :data="upData"
        :headers="headres"
        multiple
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </div>
    <div class="op">
      <el-button size="small" style="float:right;" @click="exit()"
        >取消</el-button
      >
      <el-button
        v-if="media.title.length > 0"
        type="primary"
        style="margin-right: 7px; float:right;"
        size="small"
        @click="save()"
        :loading="saveLoad"
        >保存</el-button
      >
      <el-checkbox
        style="float:right; margin-top: 14px;"
        v-model="needPublish"
        v-if="media.title.length > 0"
        >发布</el-checkbox
      >
    </div>
  </div>
</template>

<script>
import { Url } from '../../plugins/axios.js'
export default {
  data() {
    return {
      url: Url + '/file',
      filelist: [],
      tagselect: [],
      taglist: [],
      needPublish: false,
      saveLoad: false,
      media: {
        id: '',
        title: '',
        summary: '',
        type: '3',
        publish_dt: ''
      }
    }
  },
  computed: {
    headres: function() {
      return {
        Authorization: `Bearer ${window.sessionStorage.getItem('token')}`
      }
    },
    upData: function() {
      return {
        type: parseInt(this.media.type) - 2,
        objective_id: this.media.id
      }
    }
  },
  watch: {
    media: {
      handler() {
        this.$store.state.media.media = this.media
      },
      deep: true
    },
    tagselect: {
      handler() {
        this.$store.state.media.tagselect = this.tagselect
      },
      deep: true
    },
    needPublish() {
      this.$store.state.media.needPublish = this.needPublish
    }
  },
  created() {
    this.getID()
    this.getTagList()
  },
  methods: {
    async delFile(id) {
      this.$msgbox('确定删除该文件吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.delete('file/id/' + id)
          if (res.status != 200) return this.$message(res.message)
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.loadfile()
        })
        .catch(() => {})
    },
    async loadfile() {
      const { data: res } = await this.$http.get('file', {
        params: {
          objective_id: this.media.id
        }
      })
      if (res.status != 200) return this.$message(res.message)
      this.filelist = res.data
    },
    async getTagList() {
      const { data: res } = await this.$http.get('tag')
      if (res.status != 200) return this.$message(res.message)
      this.taglist = res.data
    },
    async getID() {
      if (this.$store.state.media.media.id != '') {
        this.media = this.$store.state.media.media
        this.needPublish = this.$store.state.media.needPublish
        this.tagselect = this.$store.state.media.tagselect
        this.loadfile()
        return
      }
      const { data: res } = await this.$http.get('mguid')
      if (res.status != 200) return this.$message('获取ID失败')
      this.media.id = res.MGUID
      this.loadfile()
    },
    async save() {
      this.saveLoad = true
      if (!this.needPublish) delete this.media.publish_dt
      else this.media.publish_dt = new Date()
      this.media.type = parseInt(this.media.type)
      const { data: ares } = await this.$http.post('media', this.media)
      if (ares.status != 200) return this.$message(ares.message)
      if (this.tagselect.length > 0) {
        let tagidstr = this.tagselect.join('-')
        const { data: tres } = await this.$http.post('tag_use', {
          tag_id_str: tagidstr,
          objective_id: this.media.id
        })
        if (tres.status != 200) return this.$message(tres.message)
      }
      this.$message({
        message: ares.message,
        type: 'success'
      })
      this.clear()
      this.saveLoad = false
      this.$router.push('/media').catch(err => err)
      this.saveLoad = false
    },
    exit() {
      this.$msgbox('确定取消新增媒体吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(() => {
          this.clear()
          this.$http.delete('file/oid/' + this.media.id)
          this.$router.push('/media').catch(err => err)
        })
        .catch(() => {})
    },
    clear() {
      this.$store.state.contiune = ''
      this.$store.commit('reSetMedia')
    }
  }
}
</script>

<style scoped>
.addmedia {
  width: 1000px;
  margin: 0 auto;
}
.select,
.title,
.tag,
.summary,
.op,
.upload {
  margin-top: 20px;
  width: 100%;
  padding: 7px;
}
.op {
  margin-bottom: 77px;
}
.id {
  float: right;
  height: 100%;
  line-height: 31px;
}
.filelist {
  margin-bottom: 10px;
}
.imglist {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.imgdiv {
  text-align: center;
  width: 200px;
  height: 230px;
  margin: 3px;
}
</style>
