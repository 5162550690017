import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import Article from '../components/article/Article.vue'
import AddArticle from '../components/article/AddArticle.vue'
import EditArticle from '../components/article/EditArticle.vue'

import Media from '../components/media/Media.vue'
import AddMedia from '../components/media/AddMedia.vue'
import EditMedia from '../components/media/EditMedia.vue'

import File from '../components/File.vue'
import Info from '../components/Info.vue'
import Letter from '../components/Letter.vue'
import Tag from '../components/Tag.vue'

const router = new VueRouter({
  routes: [
    {
      path: '/minnn',
      component: Login,
      name: '光影日记'
    },
    {
      path: '/',
      component: Home,
      children: [
        { path: 'info', component: Info, name: '光影日记-概览' },
        { path: 'article', component: Article, name: '光影日记-文字' },
        {
          path: 'article/add',
          component: AddArticle,
          name: '光影日记-新增文字'
        },
        {
          path: 'article/edit',
          component: EditArticle,
          name: '光影日记-编辑文字'
        },
        { path: 'media', component: Media, name: '光影日记-媒体' },
        {
          path: 'media/add',
          component: AddMedia,
          name: '光影日记-新增媒体'
        },
        {
          path: 'media/edit',
          component: EditMedia,
          name: '光影日记-编辑媒体'
        },
        { path: 'letter', component: Letter, name: '光影日记-留言' },
        { path: 'tag', component: Tag, name: '光影日记-标签' },
        { path: 'file', component: File, name: '光影日记-文件' }
      ]
    }
  ]
})
Vue.use(VueRouter)

router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.name
  }

  if (to.path === '/minnn') return next()
  if (!window.sessionStorage.getItem('token')) {
    next('/minnn')
  } else {
    next()
  }
})

export default router
