import Vue from 'vue'
import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'

import 'element-ui/lib/theme-chalk/index.css'
import 'element-tiptap/lib/index.css'
import './element-ui-style/theme/index.css'

Vue.use(ElementUI)
Vue.use(ElementTiptapPlugin, {
  lang: 'zh'
})
