import Vue from 'vue'
import axios from 'axios'

const Url = 'https://api.lpqo.cn'
// const Url = 'http://localhost:2782'

axios.defaults.baseURL = Url

axios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${window.sessionStorage.getItem(
    'token'
  )}`
  return config
})

Vue.prototype.$http = axios

export { Url }
