<template>
  <div v-loading="dataLoad" class="editarticle">
    <div class="select">
      <div class="id">{{ article.id }}</div>
      <el-radio-group v-model="article.type" size="small">
        <el-radio-button label="1">文章</el-radio-button>
        <el-radio-button label="2">随笔</el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="article.id.length > 0" class="title">
      <el-input
        style="font-size:16px"
        type="textarea"
        autosize
        placeholder="标题"
        v-model="article.title"
        maxlength="300"
        show-word-limit
      >
      </el-input>
    </div>
    <div class="tag">
      <el-checkbox-group v-model="tagselect" size="mini">
        <el-checkbox v-for="i in taglist" :key="i.ID" :label="i.ID">{{
          i.title
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-if="article.id.length > 0 && article.type == 1" class="summary">
      <el-input
        style="font-size:16px"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 6 }"
        placeholder="简介"
        v-model="article.summary"
        maxlength="300"
        show-word-limit
      >
      </el-input>
    </div>
    <div v-if="article.id.length > 0 && article.type == 1" class="content">
      <el-tiptap
        :readonly="false"
        :showMenubar="true"
        placeholder="内容"
        v-model="article.content"
        :extensions="extensions"
      />
    </div>
    <div class="op">
      <el-button size="small" style="float:right;" @click="exit()"
        >取消</el-button
      >
      <el-button
        v-if="article.title.length > 0"
        type="primary"
        style="margin-right: 7px; float:right;"
        size="small"
        @click="save()"
        :loading="saveLoad"
        >保存</el-button
      >
      <el-checkbox
        style="float:right; margin-top: 14px;"
        v-model="needPublish"
        v-if="article.title.length > 0"
        >发布</el-checkbox
      >
    </div>
  </div>
</template>

<script>
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  Code,
  CodeBlock,
  Blockquote,
  Link,
  Image,
  ListItem,
  BulletList,
  OrderedList,
  TodoList,
  TodoItem,
  Iframe,
  Table,
  TableCell,
  TableRow,
  TableHeader,
  TextAlign,
  LineHeight,
  Indent,
  HorizontalRule,
  HardBreak,
  TrailingNode,
  History,
  TextColor,
  TextHighlight,
  FormatClear,
  FontType,
  FontSize,
  Preview,
  Print,
  Fullscreen,
  SelectAll,
  CodeView
} from 'element-tiptap'
import codemirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/xml/xml.js'

export default {
  data() {
    return {
      taglist: [],
      tagselect: [],
      needPublish: false,
      dataLoad: false,
      saveLoad: false,
      article: {
        id: '',
        title: '',
        summary: '',
        type: 1,
        content: '',
        publish_dt: ''
      },
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold(),
        new Underline(),
        new Italic(),
        new Strike(),
        new Code(),
        new CodeBlock(),
        new Blockquote(),
        new Link(),
        new Image({
          uploadRequest: async file => {
            var url = ''
            var fd = new FormData()
            fd.append('file', file)
            fd.append('type', 1)
            fd.append('objective_id', this.article.id)
            await this.$http.post('file', fd).then(res => {
              if (res.data.status !== 200) {
                this.$message(res.data.message)
                return
              }
              url = res.data.url + '!w10'
            })
            return url
          }
        }),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TodoList(),
        new TodoItem(),
        new Iframe(),
        new Table({
          resizable: true
        }),
        new TableHeader(),
        new TableRow(),
        new TableCell(),
        new TextAlign({
          alignments: ['left', 'center', 'right', 'justify']
        }),
        new LineHeight({
          lineHeights: ['50%', '100%', '150%', '200%']
        }),
        new Indent({
          minIndent: 0,
          maxIndent: 7
        }),
        new HorizontalRule(),
        new HardBreak(),
        new TrailingNode(),
        new History(),
        new TextColor({
          colors: [
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#cddc39',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#000000'
          ]
        }),
        new TextHighlight({
          colors: [
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#cddc39',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#000000'
          ]
        }),
        new FormatClear(),
        new FontType({
          fontTypes: {
            Arial: 'Arial',
            'Arial Black': 'Arial Black',
            Georgia: 'Georgia',
            Impact: 'Impact',
            Tahoma: 'Tahoma',
            'Times New Roman': 'Times New Roman',
            Verdana: 'Verdana',
            'Courier New': 'Courier New',
            'Lucida Console': 'Lucida Console',
            Monaco: 'Monaco',
            monospace: 'monospace'
          }
        }),
        new FontSize({
          fontSizes: [
            '8',
            '10',
            '12',
            '14',
            '16',
            '18',
            '20',
            '24',
            '30',
            '36',
            '48',
            '60',
            '72'
          ]
        }),
        new Preview(),
        new Print(),
        new Fullscreen(),
        new SelectAll(),
        new CodeView({
          codemirror: codemirror,
          codemirrorOptions: {
            lineNumbers: true,
            lineWrapping: true,
            tabSize: 2,
            tabMode: 'indent',
            mode: 'text/html'
          }
        })
      ]
    }
  },
  created() {
    this.getArticle()
    this.getTagList()
  },
  watch: {
    article: {
      handler() {
        this.$store.state.article = this.article
      },
      deep: true
    },
    tagselect: {
      handler() {
        this.$store.state.tagselect = this.tagselect
      },
      deep: true
    },
    needPublish() {
      this.$store.state.ArticleNeedPublish = this.needPublish
    }
  },
  methods: {
    async getTagList() {
      const { data: res } = await this.$http.get('tag')
      if (res.status != 200) return this.$message(res.message)
      this.taglist = res.data
    },
    async getArticle() {
      if (this.$store.state.article.id == '') {
        this.$message('获取ID失败')
        return
      }
      this.article.id = this.$store.state.article.id
      if (this.$store.state.article.title != '') {
        this.article = this.$store.state.article
        this.needPublish = this.$store.state.ArticleNeedPublish
        this.tagselect = this.$store.state.tagselect
        return
      }
      this.dataLoad = true
      const { data: res } = await this.$http.get('article/' + this.article.id)
      this.getTagSelect(this.article.id)
      if (res.status != 200) return this.$message(res.message)
      this.article.title = res.data.title
      this.article.summary = res.data.summary
      this.article.type = res.data.type
      this.article.content = res.data.content
      this.article.publish_dt = res.data.publish_dt
      if (this.article.publish_dt != null) this.needPublish = true
      this.dataLoad = false
    },
    async getTagSelect(id) {
      this.$http
        .get('t', {
          params: {
            objective_id: id
          }
        })
        .then(res => {
          let tagObj = res.data.data
          tagObj.forEach(el => {
            this.tagselect.push(el.ID)
          })
        })
    },
    exit() {
      this.$msgbox('确定取消编辑文字吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(() => {
          this.clear()
          this.$router.push('/article').catch(err => err)
        })
        .catch(() => {})
    },
    async save() {
      this.saveLoad = true
      if (!this.needPublish) {
        delete this.article.publish_dt
      } else this.article.publish_dt = new Date()
      this.article.type = parseInt(this.article.type)
      const { data: res } = await this.$http.put(
        'article/e/' + this.article.id,
        this.article
      )
      if (res.status != 200) return this.$message(res.message)

      const { data: dres } = await this.$http.delete(
        'tag_del_oid/' + this.article.id
      )
      if (dres.status != 200) return this.$message(dres.message)

      if (this.tagselect.length != 0) {
        let tagidstr = this.tagselect.join('-')
        const { data: tres } = await this.$http.post('tag_use', {
          tag_id_str: tagidstr,
          objective_id: this.article.id
        })
        if (tres.status != 200) return this.$message(tres.message)
      }

      this.$message({
        message: res.message,
        type: 'success'
      })
      this.clear()
      this.saveLoad = false
      this.$router.push('/article').catch(err => err)
    },
    clear() {
      this.$store.state.contiune = ''
      this.$store.commit('reSetArticle')
    }
  }
}
</script>

<style scoped>
.editarticle {
  width: 1000px;
  margin: 0 auto;
}
.select,
.title,
.tag,
.summary,
.content,
.op {
  margin-top: 20px;
  width: 100%;
  padding: 7px;
}
.op {
  margin-bottom: 77px;
}
.id {
  float: right;
  height: 100%;
  line-height: 31px;
}
</style>
