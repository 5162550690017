<template>
  <div class="info">
    <div class="infoDis">
      <div class="infoState" v-loading="stateLoading">
        <div class="stateDis">
          <el-progress
            :type="state.type"
            :color="state.color"
            :width="state.width"
            :percentage="state.CPU"
          ></el-progress>
          <el-progress
            :type="state.type"
            :color="state.color"
            :width="state.width"
            :percentage="state.MEM"
          ></el-progress>
          <el-progress
            :type="state.type"
            :color="state.color"
            :width="state.width"
            :percentage="state.DISK"
          ></el-progress>
        </div>
        <div class="stateInf">
          <label>CPU</label>
          <label>内存</label>
          <label>硬盘</label>
        </div>
      </div>
      <div class="infoCounts" v-loading="countsLoading">
        <div>
          <p>{{ counts.article }}</p>
          <label>文字</label>
        </div>
        <div>
          <p>{{ counts.media }}</p>
          <label>媒体</label>
        </div>
        <div>
          <p>{{ counts.letter }}</p>
          <label>留言</label>
        </div>
        <div>
          <p>{{ counts.tag }}</p>
          <label>标签</label>
        </div>
        <div>
          <p>{{ counts.file }}</p>
          <label>文件</label>
        </div>
      </div>
    </div>
    <div class="selectlist">
      <el-date-picker
        v-model="date_picker"
        @change="doSch"
        type="daterange"
        size="small"
        style="width: 227px;margin-top: 9px;"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-input
        placeholder="IP"
        size="small"
        v-model="query.ip"
        @input="doSch"
        style="width: 160px"
        clearable
      ></el-input>
      <el-input
        placeholder="内容"
        size="small"
        v-model="query.path"
        @input="doSch"
        style="width: 210px"
        clearable
      ></el-input>
    </div>

    <el-table :data="list" v-loading="listloading">
      <el-table-column
        prop="ID"
        width="121"
        align="center"
        label="ID"
      ></el-table-column>
      <el-table-column
        prop="CreatedAt"
        label="记录时间"
        width="155"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ip"
        label="IP"
        width="121"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="method"
        label="方法"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="path"
        label="内容"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="query.pagenum"
      :page-size="query.pagesize"
      :page-sizes="[50, 100, 500, 1000, 6000]"
      layout="total, prev, pager, next, sizes"
      :total="total"
    >
    </el-pagination>

    <div style="height: 27px; width: 100%"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        type: 'circle',
        color: '#404040',
        width: 67,
        CPU: 0,
        MEM: 0,
        DISK: 0
      },
      counts: {
        article: 0,
        file: 0,
        letter: 0,
        media: 0,
        tag: 0
      },
      list: [],
      listloading: true,
      stateLoading: true,
      countsLoading: true,
      total: 0,
      date_picker: [],
      query: {
        ip: '',
        path: '',
        created_at_f: '',
        created_at_l: '',
        pagesize: 50,
        pagenum: 1
      }
    }
  },
  created() {
    this.getState()
    this.stateTimer = setInterval(() => {
      this.getState()
    }, 2000)
    this.loadList()
    this.getCounts()
  },
  beforeDestroy() {
    clearInterval(this.stateTimer)
  },
  methods: {
    async getCounts() {
      const { data: res } = await this.$http.get('admin/counts')
      if (res.status != 200) return this.$message(res.message)
      this.counts = res.data
      this.countsLoading = false
    },
    async loadList() {
      this.listloading = true
      if (this.date_picker == null) {
        this.query.created_at_f = ''
        this.query.created_at_l = ''
      } else if (this.date_picker.length == 0) {
        this.query.created_at_f = ''
        this.query.created_at_l = ''
      } else {
        this.query.created_at_f = this.date_picker[0]
        this.query.created_at_l = this.date_picker[1]
      }
      const { data: res } = await this.$http.get('log', {
        params: this.query
      })
      if (res.status != 200) return this.$message(res.message)
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].CreatedAt = this.formatDate(
          res.data[i].CreatedAt,
          '暂无',
          true
        )
      }
      this.list = res.data
      this.total = res.total
      this.listloading = false
    },
    currentChange(val) {
      this.query.pagenum = val
      this.loadList()
    },
    sizeChange(val) {
      this.query.pagesize = val
      this.loadList()
    },
    async getState() {
      const { data: res } = await this.$http.get('admin/state')
      if (res.status != 200) return this.$message(res.message)
      this.state.CPU = parseFloat(res.CPU.toFixed(2))
      this.state.MEM = parseFloat(res.MEM.toFixed(2))
      this.state.DISK = parseFloat(res.DISK.toFixed(2))
      this.stateLoading = false
    },
    doSch() {
      this.query.pagenum = 1
      this.loadList()
    }
  }
}
</script>

<style scoped>
.info {
  width: 1000px;
  margin: 0 auto;
}

.infoDis {
  width: 100%;
  height: 127px;
  display: flex;
  justify-content: space-around;
}

.infoCounts {
  width: 470px;
  padding: 37px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.infoCounts div {
  width: 100px;
  height: 100%;
  line-height: 100%;
  text-align: center;
  color: #404040;
}

.infoCounts div p {
  font-size: 27px;
  line-height: 127%;
  font-weight: 270px;
}

.infoState {
  width: 370px;
  height: 100%;
}

.stateDis {
  display: flex;
  justify-content: space-between;
  margin: 27px auto 7px;
  width: 100%;
}

.stateInf {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
}

.stateInf label {
  width: 67px;
  text-align: center;
  color: #404040;
}

.selectlist {
  height: 50px;
  width: 620px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 0px;
}
</style>
