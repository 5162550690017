<template>
  <div class="letter">
    <div class="selectlist">
      <el-select
        v-model="query.not_publish"
        @change="doSch"
        size="small"
        style="width: 87px"
        placeholder="请选择"
      >
        <el-option label="全部" value="0"></el-option>
        <el-option label="未通过" value="1"></el-option>
        <el-option label="已通过" value="2"></el-option>
      </el-select>
      <el-date-picker
        v-model="date_picker"
        @change="doSch"
        type="daterange"
        size="small"
        style="width: 227px;margin-top: 9px;"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-input
        placeholder="ID"
        size="small"
        v-model="query.id"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
      <el-input
        placeholder="所属ID"
        size="small"
        v-model="query.objective_id"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
      <el-input
        placeholder="内容"
        size="small"
        v-model="query.content"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
      <el-input
        placeholder="称呼"
        size="small"
        v-model="query.possessor"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
      <el-input
        placeholder="联系方式"
        size="small"
        v-model="query.links"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
    </div>
    <div class="list" v-loading="listloading">
      <div v-if="empty" class="empty">暂无数据</div>
      <el-card shadow="never" class="item" v-for="i in list" :key="i.ID">
        <p>
          {{ i.possessor }} - {{ i.links }}
          <label> <i class="el-icon-right"></i> {{ i.oidInf.type }}「</label>
          <el-link
            @click="goInf(i.oidInf.type, i.objective_id)"
            style="font-size: 15px;margin-top:-4px;"
            type="primary"
            >{{ i.oidInf.title }}</el-link
          >」
        </p>
        <p style="margin: 10px 0px">{{ i.content }}</p>
        <p>
          <el-link
            style="font-size: 15px;margin-top:-4px;"
            icon="el-icon-delete"
            @click="delTetter(i.ID)"
          ></el-link>
          {{ i.ID }} - {{ i.CreatedAt }}留言 -
          <el-link
            style="font-size: 15px;margin-top:-4px;"
            type="danger"
            @click="doPublish(true, i.ID)"
            v-if="i.publish_dt == '尚未通过'"
            >{{ i.publish_dt }}</el-link
          ><el-link
            style="font-size: 15px;margin-top:-4px;"
            type="primary"
            @click="doPublish(false, i.ID)"
            v-else
            >{{ i.publish_dt }}</el-link
          >
        </p>
      </el-card>
    </div>
    <el-pagination
      layout="total, prev, pager, next"
      :page-size="query.pagesize"
      :current-page="query.pagenum"
      :total="total"
      @current-change="currentChange"
    >
    </el-pagination>
    <div style="height: 27px; width: 100%"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      empty: false,
      list: [],
      total: 0,
      listloading: false,
      date_picker: [],
      query: {
        id: '',
        objective_id: '',
        pagesize: 15,
        pagenum: 1,
        created_at_f: '',
        created_at_l: '',
        not_publish: '0',
        content: '',
        possessor: '',
        links: ''
      }
    }
  },
  created() {
    this.loadlist()
  },
  methods: {
    delTetter(id) {
      this.$msgbox('确定删除该留言吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.delete('letter/' + id)
          if (res.status != 200) return this.$message(res.message)
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.loadlist()
        })
        .catch(() => {})
    },
    doPublish(b, id) {
      if (b) {
        this.$msgbox('确定通过吗？', '光影日记', {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await this.$http.put('letter/p/' + id)
            if (res.status != 200) return this.$message(res.message)
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.loadlist()
          })
          .catch(() => {})
      } else {
        this.$msgbox('确定撤销通过吗？', '光影日记', {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await this.$http.put('letter/r/' + id)
            if (res.status != 200) return this.$message(res.message)
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.loadlist()
          })
          .catch(() => {})
      }
    },
    goInf(type, id) {
      if (type == '媒体') {
        this.$store.state.media.loadID = id
        this.$router.push('/media').catch(err => err)
      } else if (type == '文字') {
        this.$store.state.articleLoadID = id
        this.$router.push('/article').catch(err => err)
      }
    },
    currentChange(val) {
      this.$store.state.letter.pagenum = val
      this.loadlist()
    },
    doSch() {
      this.$store.state.letter.pagenum = 1
      this.loadlist()
    },
    async loadlist() {
      this.listloading = true
      this.empty = false
      this.query.pagenum = this.$store.state.letter.pagenum
      var objective_id = this.$store.state.letter.loadID
      this.$store.state.letter.loadID = ''
      if (objective_id != '') this.query.objective_id = objective_id
      if (this.date_picker == null) {
        this.query.created_at_f = ''
        this.query.created_at_l = ''
      } else if (this.date_picker.length == 0) {
        this.query.created_at_f = ''
        this.query.created_at_l = ''
      } else {
        this.query.created_at_f = this.date_picker[0]
        this.query.created_at_l = this.date_picker[1]
      }
      const { data: res } = await this.$http.get('letter', {
        params: this.query
      })
      if (res.status != 200) return this.$message(res.message)
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].CreatedAt = this.formatDate(
          res.data[i].CreatedAt,
          '暂无',
          true
        )
        res.data[i].publish_dt =
          this.formatDate(res.data[i].publish_dt, '尚未', true) + '通过'
        if (res.data[i].links == '') res.data[i].links = '无联系方式'

        res.data[i].oidInf = {}
        this.getOIDInf(res.data[i].objective_id)
      }
      this.list = res.data
      this.total = res.total
      if (res.total == 0) this.empty = true
      this.listloading = false
    },
    async getOIDInf(id) {
      if (id == '') return
      const res = await this.$http.get('check/' + id)
      if (res.data.status != 200) return
      this.list.forEach(el => {
        if (el.objective_id == id) {
          if (res.data.title.length > 15)
            res.data.title = res.data.title.substr(0, 14) + '...'
          el.oidInf = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.item {
  margin: 12px auto;
}
.letter {
  width: 1170px;
  margin: 0 auto;
}
.selectlist {
  height: 50px;
  width: 1170px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 0px;
}
.list {
  margin-top: 10px;
}
</style>
