<template>
  <div class="home">
    <div class="header">
      <div class="h_op">
        <div class="h_logo"></div>
        <el-link
          :type="backDisType"
          style="user-select: none; margin-left: 17px"
          @click="back()"
          >{{ c.text }}</el-link
        >
      </div>
      <div class="h_tag">
        <el-link
          style="user-select: none;"
          @click="doTagLink(0)"
          :type="tagLink[0].type"
          >概览</el-link
        >
        <el-link
          style="user-select: none;"
          @click="doTagLink(1)"
          :type="tagLink[1].type"
          >文字</el-link
        >
        <el-link
          style="user-select: none;"
          @click="doTagLink(2)"
          :type="tagLink[2].type"
          >媒体</el-link
        >
        <el-link
          style="user-select: none;"
          @click="doTagLink(3)"
          :type="tagLink[3].type"
          >留言</el-link
        >
        <el-link
          style="user-select: none;"
          @click="doTagLink(4)"
          :type="tagLink[4].type"
          >标签</el-link
        >
        <el-link
          style="user-select: none;"
          @click="doTagLink(5)"
          :type="tagLink[5].type"
          >文件</el-link
        >
        <el-link style="user-select: none;" @click="loginOut()" type="info"
          >退出登录</el-link
        >
      </div>
    </div>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRouterAlive: true,
      c: {
        need: false,
        text: '',
        path: ''
      },
      backDisType: 'primary',
      tagLink: [
        { path: '/info', type: 'primary' },
        { path: '/article', type: 'primary' },
        { path: '/media', type: 'primary' },
        { path: '/letter', type: 'primary' },
        { path: '/tag', type: 'primary' },
        { path: '/file', type: 'primary' }
      ]
    }
  },
  created() {
    this.tagLinkChange()
    this.backDis()
  },
  watch: {
    $route() {
      this.tagLinkChange()
      this.backDis()
    }
  },
  methods: {
    back() {
      this.$router.push(this.c.path).catch(err => err)
    },
    backDis() {
      const con = this.$store.state.contiune
      if (con == this.$route.path) this.backDisType = 'success'
      else this.backDisType = 'primary'
      this.c.path = con
      if (con == '/article/add') {
        this.c.text = '新增文字'
        this.c.need = true
      } else if (con == '/article/edit') {
        this.c.text = '编辑文字'
        this.c.need = true
      } else if (con == '/media/add') {
        this.c.text = '新增媒体'
        this.c.need = true
      } else if (con == '/media/edit') {
        this.c.text = '编辑媒体'
        this.c.need = true
      } else {
        this.c.path = ''
        this.c.text = ''
        this.c.need = false
      }
    },
    doTagLink(index) {
      clearInterval(this.stateTimer)
      if (this.tagLink[index].path == this.$route.path) {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      }
      this.$router.push(this.tagLink[index].path).catch(err => err)
    },
    tagLinkChange() {
      var path = this.$route.path
      for (let i = 0; i < this.tagLink.length; i++) {
        if (this.tagLink[i].path == path) {
          this.tagLink[i].type = 'success'
        } else {
          this.tagLink[i].type = 'primary'
        }
      }
    },
    loginOut() {
      this.$msgbox('确定退出登录吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(() => {
          window.sessionStorage.clear()
          this.$router.push('/minnn').catch(err => err)
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.home {
  --h_logo: url(../assets/a_b.png);
}
.header {
  height: 50px;
  width: 1000px;
  margin: 7px auto;
  display: flex;
  justify-content: space-between;
}
.h_op {
  height: 100%;
  width: 300px;
  display: flex;
}
.h_logo {
  height: 50px;
  width: 175.5px;
  background: var(--h_logo), no-repeat;
  background-size: 100%;
}
.h_tag {
  height: 100%;
  width: 370px;
  display: flex;
  justify-content: space-between;
}
</style>
