<template>
  <div class="file">
    <div class="selectlist">
      <el-radio-group
        style="margin-top: 9px;"
        @change="doSch"
        v-model="query.type"
        size="small"
      >
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button label="1">图片</el-radio-button>
        <el-radio-button label="2">视频</el-radio-button>
        <el-radio-button label="3">音频</el-radio-button>
        <el-radio-button label="4">文件</el-radio-button>
      </el-radio-group>
      <el-input
        placeholder="所属ID"
        size="small"
        v-model="query.objective_id"
        @input="doSch"
        style="width: 160px"
        clearable
      ></el-input>
      <el-input
        placeholder="链接"
        size="small"
        v-model="query.links"
        @input="doSch"
        style="width: 160px"
        clearable
      ></el-input>
    </div>
    <el-table :data="list" v-loading="listloading">
      <el-table-column
        prop="ID"
        width="121"
        align="center"
        label="ID"
      ></el-table-column>
      <el-table-column width="77" align="center" label="类型">
        <template slot-scope="scope">
          <span>{{ scope.row.oidtype.type }}</span>
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="UpdatedAt"
        label="更新时间"
        width="155"
        align="center"
      ></el-table-column>
      <el-table-column label="所属" width="244" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.oidtype.type == null">无所属</span>
          <el-link
            v-else
            @click="goInf(scope.row.oidtype.type, scope.row.objective_id)"
            >{{ scope.row.oidtype.title }}</el-link
          >
        </template></el-table-column
      >
      <el-table-column align="center" label="链接">
        <template slot-scope="scope">
          <el-link target="_blank" :href="scope.row.links">{{
            scope.row.links
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column width="50" align="center">
        <template slot="header">
          <el-button
            size="mini"
            icon="el-icon-plus"
            @click="updis = !updis"
            circle
          ></el-button
        ></template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-delete"
            @click="deleteFile(scope.row.ID)"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, prev, pager, next"
      :page-size="query.pagesize"
      :current-page="query.pagenum"
      :total="total"
      @current-change="currentChange"
    >
    </el-pagination>
    <el-dialog title="上传文件" :visible.sync="updis" width="370px">
      <el-radio-group
        style="margin: -25px 0 10px 0;"
        v-model="upType"
        size="small"
      >
        <el-radio-button label="1">图片</el-radio-button>
        <el-radio-button label="2">视频</el-radio-button>
        <el-radio-button label="3">音频</el-radio-button>
        <el-radio-button label="4">文件</el-radio-button>
      </el-radio-group>
      <el-upload
        :action="url"
        :data="upData"
        :headers="headres"
        :on-success="doSch"
        multiple
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </el-dialog>
    <div style="height: 27px; width: 100%"></div>
  </div>
</template>

<script>
import { Url } from '../plugins/axios.js'
export default {
  data() {
    return {
      updis: false,
      upType: 1,
      url: Url + '/file',
      list: [],
      listloading: true,
      total: 0,
      query: {
        type: '',
        links: '',
        objective_id: '',
        pagesize: 20,
        pagenum: 1
      }
    }
  },
  computed: {
    headres: function() {
      return {
        Authorization: `Bearer ${window.sessionStorage.getItem('token')}`
      }
    },
    upData: function() {
      return {
        type: this.upType
      }
    }
  },
  created() {
    this.loadlist()
  },
  methods: {
    deleteFile(id) {
      this.$msgbox('确定删除该文件吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.delete('file/id/' + id)
          if (res.status != 200) return this.$message(res.message)
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.doSch()
        })
        .catch(() => {})
    },
    goInf(type, id) {
      if (type == '媒体') {
        this.$store.state.media.loadID = id
        this.$router.push('/media').catch(err => err)
      } else if (type == '文字') {
        this.$store.state.articleLoadID = id
        this.$router.push('/article').catch(err => err)
      }
    },
    currentChange(val) {
      this.$store.state.file.pagenum = val
      this.loadlist()
    },
    doSch() {
      this.$store.state.file.pagenum = 1
      this.loadlist()
    },
    async loadlist() {
      this.listloading = true
      this.query.pagenum = this.$store.state.file.pagenum
      var objective_id = this.$store.state.file.loadID
      this.$store.state.file.loadID = ''
      if (objective_id != '') this.query.objective_id = objective_id
      const { data: res } = await this.$http.get('file', {
        params: this.query
      })
      if (res.status != 200) return this.$message(res.message)
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].UpdatedAt = this.formatDate(
          res.data[i].UpdatedAt,
          '暂无',
          true
        )
        switch (res.data[i].type) {
          case 0:
            res.data[i].type = '未知'
            break
          case 1:
            res.data[i].type = '图片'
            break
          case 2:
            res.data[i].type = '视频'
            break
          case 3:
            res.data[i].type = '音频'
            break
          case 4:
            res.data[i].type = '文件'
            break
        }
        res.data[i].oidtype = {}
        this.getOIDType(res.data[i].objective_id)
      }
      this.list = res.data
      this.total = res.total
      this.listloading = false
    },
    async getOIDType(id) {
      if (id == '') return
      const res = await this.$http.get('check/' + id)
      if (res.data.status != 200) return
      this.list.forEach(el => {
        if (el.objective_id == id) {
          if (res.data.title.length > 15)
            res.data.title = res.data.title.substr(0, 14) + '...'
          el.oidtype = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.file {
  width: 1000px;
  margin: 0 auto;
}
.selectlist {
  height: 50px;
  width: 620px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 0px;
}
</style>
