import Vue from 'vue'
import store from './vuex.js'
import router from './router/index.js'
import App from './App.vue'

import './plugins/axios.js'
import './plugins/element.js'
import './plugins/tiptap.js'

Vue.config.productionTip = false

Vue.prototype.formatDate = (data, msg, needDetail) => {
  if (
    data == null ||
    data == '' ||
    data == undefined ||
    data == '0001-01-01T00:00:00Z'
  ) {
    return msg
  }
  var fd = new Date(data)
  var year = fd.getFullYear()
  var month = fd.getMonth() + 1
  var day = fd.getDate()
  var hour = fd.getHours()
  var minute = fd.getMinutes()
  var second = fd.getSeconds()
  if (needDetail)
    return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':')
  else return [year, month, day].join('-')
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
