<template>
  <div class="tag">
    <div class="selectlist">
      <el-input
        placeholder="ID"
        size="small"
        v-model="query.id"
        @input="loadList"
        style="width: 160px"
        clearable
      ></el-input>
      <el-input
        placeholder="内容"
        size="small"
        v-model="query.title"
        @input="loadList"
        style="width: 160px"
        clearable
      ></el-input>
      <el-input
        placeholder="所属ID"
        size="small"
        v-model="query.objective_id"
        @input="loadList"
        style="width: 160px"
        clearable
      ></el-input>
      <el-button
        icon="el-icon-plus"
        style="  height: 32px;margin-top: 9px;padding: 5px 7px;"
        @click="add"
        >增加</el-button
      >
    </div>
    <div v-if="empty" class="empty">暂无数据</div>
    <div v-loading="listloading" class="list">
      <el-card
        v-for="i in list"
        :key="i.ID"
        :style="{ height: i.height + 'px' }"
        style="break-inside: avoid;margin-bottom: 16px;"
        shadow="never"
      >
        <div class="listid">
          <p>{{ i.title }}</p>
          <p>
            {{ i.ID }}
            <el-link
              style="margin-left: 7px;margin-top:-4px;"
              size="mini"
              @click="delTag(i.ID)"
              ><i class="el-icon-delete"></i>
            </el-link>
          </p>
        </div>
        <p style="margin: 5px 0;text-align: center;">
          <span v-if="i.total != 0"
            >以下{{ i.total }}个文字或媒体选择了该标签</span
          >
          <span v-else>没有文字或媒体选择了该标签</span>
        </p>
        <div v-for="t in i.inf" :key="t.ID">
          <p style="height: 23px;" v-if="t.type < 3">
            文字：
            <el-link
              style="font-size: 14px;margin-top:-4px;"
              target="_blank"
              @click="goArcitle(t.ID)"
              >{{ t.title.substr(0, 17) }}</el-link
            >
          </p>
          <p style="height: 23px;" v-else>
            媒体：
            <el-link
              style="font-size: 14px;margin-top:-4px;"
              target="_blank"
              @click="goMedia(t.ID)"
              >{{ t.title }}</el-link
            >
          </p>
        </div>
      </el-card>
    </div>
    <p style="color: #303133; font-size: 14px;">共 {{ total }} 个标签内容</p>
    <div style="height: 27px; width: 100%"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      empty: false,
      listloading: false,
      query: {
        id: '',
        title: '',
        objective_id: ''
      },
      list: [],
      total: 0
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    goArcitle(id) {
      this.$store.state.articleLoadID = id
      this.$router.push('/article').catch(err => err)
    },
    goMedia(id) {
      this.$store.state.media.loadID = id
      this.$router.push('/media').catch(err => err)
    },
    delTag(id) {
      this.$msgbox('确定删除该标签吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.delete('tag/' + id)
          if (res.status != 200) return this.$message(res.message)
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.loadList()
        })
        .catch(() => {})
    },
    async loadList() {
      this.listloading = true
      this.empty = false
      var load_id = this.$store.state.tag.loadID
      this.$store.state.tag.loadID = ''
      if (load_id != '') this.query.id = load_id
      const { data: res } = await this.$http.get('tag', {
        params: this.query
      })
      if (res.status != 200) return this.$message(res.message)
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].UpdatedAt = this.formatDate(
          res.data[i].UpdatedAt,
          '暂无',
          true
        )
      }
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].inf = []
        res.data[i].total = 0
        this.getInf(res.data[i].ID)
      }
      this.list = res.data
      if (res.total == 0) this.empty = true
      this.total = res.total
      this.listloading = false
    },
    async getInf(id) {
      this.$http
        .get('h', {
          params: {
            tag_id: id
          }
        })
        .then(res => {
          if (res.data.status != 200) return this.$message(res.message)
          this.list.forEach(el => {
            if (el.ID == id) {
              el.inf = res.data.data
              el.total = res.data.total
              el.height = 90 + el.total * 23
            }
          })
        })
    },
    add() {
      this.$prompt('请输入标签名', '光影日记', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async ({ value }) => {
          if (value == null) return this.$message('标签名不能为空')
          const { data: res } = await this.$http.post('tag', {
            title: value
          })
          if (res.status != 200) return this.$message(res.message)
          this.$message({
            type: 'success',
            message: res.message
          })
          this.loadList()
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.tag {
  width: 1000px;
  margin: 0 auto;
}
.selectlist {
  height: 50px;
  width: 570px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 0px;
}
.list {
  column-count: 3;
  margin-top: 10px;
}
.listid {
  display: flex;
  justify-content: space-between;
}
</style>
