import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    contiune: '',
    file: {
      pagenum: 1,
      loadID: ''
    },
    tag: {
      loadID: ''
    },
    media: {
      loadID: '',
      pagenum: 1,

      tagselect: [],
      needPublish: false,
      media: {
        id: '',
        title: '',
        summary: '',
        type: 1,
        publish_dt: ''
      }
    },
    letter: {
      loadID: '',
      pagenum: 1
    },
    // article
    articleLoadID: '',
    tagselect: [],
    ArticlePagenum: 1,
    ArticleNeedPublish: false,
    article: {
      id: '',
      title: '',
      summary: '',
      type: 1,
      content: '',
      publish_dt: ''
    }
  },
  mutations: {
    reSetArticle(state) {
      state.article = {
        id: '',
        title: '',
        summary: '',
        type: 1,
        content: '',
        publish_dt: ''
      }
      state.ArticleNeedPublish = false
      state.tagselect = []
    },
    reSetMedia(state) {
      state.media.media = {
        id: '',
        title: '',
        summary: '',
        type: 1,
        content: '',
        publish_dt: ''
      }
      state.media.needPublish = false
      state.media.tagselect = []
    }
  }
})
