<template>
  <div class="media">
    <div class="selectlist">
      <el-select
        v-model="query.not_publish"
        @change="doSch"
        size="small"
        style="width: 87px"
        placeholder="请选择"
      >
        <el-option label="全部" value="0"></el-option>
        <el-option label="未发布" value="1"></el-option>
        <el-option label="已发布" value="2"></el-option>
      </el-select>
      <el-select
        v-model="query.type"
        @change="doSch"
        size="small"
        style="width: 87px"
        placeholder="请选择"
      >
        <el-option label="全部" value="0"></el-option>
        <el-option label="照片" value="3"></el-option>
        <el-option label="视频" value="4"></el-option>
        <el-option label="音频" value="5"></el-option>
        <el-option label="文件" value="6"></el-option>
      </el-select>
      <el-date-picker
        v-model="date_picker"
        @change="doSch"
        type="daterange"
        size="small"
        style="width: 227px;margin-top: 9px;"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-input
        placeholder="ID"
        size="small"
        v-model="query.id"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
      <el-input
        placeholder="标题"
        size="small"
        v-model="query.title"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
      <el-input
        placeholder="简介"
        size="small"
        v-model="query.summary"
        @input="doSch"
        style="width: 160px"
        clearable
      >
      </el-input>
    </div>
    <el-table :data="list" v-loading="listloading">
      <el-table-column
        prop="ID"
        label="ID"
        width="121"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="UpdatedAt"
        label="更新时间"
        width="155"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="publish_dt"
        label="发布时间"
        width="154"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        prop="visit_num"
        label="访问次数"
        width="78"
        align="center"
      ></el-table-column> -->
      <el-table-column label="标题" align="center">
        <template slot-scope="scope">
          <el-link
            target="_blank"
            :href="'https://blog.lpqo.cn/link/' + scope.row.ID"
            >{{ scope.row.title }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="summary"
        label="简介"
        align="center"
      ></el-table-column>
      <el-table-column width="50" align="center">
        <template slot="header">
          <el-button
            size="mini"
            icon="el-icon-plus"
            @click="addMedia()"
            circle
          ></el-button
        ></template>
        <template slot-scope="scope">
          <el-popover placement="right" width="300" trigger="click">
            <div>
              <p>
                {{ scope.row.ID }}
                <el-link
                  style="margin-left: 5px;font-size: 14px;"
                  @click="goLetter(scope.row.ID)"
                  >查看留言</el-link
                >
                <el-link
                  style="margin-left: 5px;font-size: 14px;"
                  @click="goFile(scope.row.ID)"
                  >查看文件</el-link
                >
              </p>
              <p style="margin: 3px 0 3px 0;">{{ scope.row.title }}</p>
              <p style="margin: 3px 0 10px 0;">
                <el-link
                  v-for="t in scope.row.taglist"
                  style="margin-left: 5px;font-size: 14px;"
                  :key="t.ID"
                  @click="goTag(t.ID)"
                  icon="el-icon-collection-tag"
                  >{{ t.title }}</el-link
                >
              </p>
              <el-button
                @click="doPublish(true, scope.row.ID)"
                v-if="scope.row.publish_dt == '未发布'"
                size="small"
              >
                发布
              </el-button>
              <el-button
                @click="doPublish(false, scope.row.ID)"
                v-else
                size="small"
                >撤销发布</el-button
              >
              <el-button @click="doDel(scope.row.ID)" size="small"
                >删除</el-button
              >
              <el-button @click="doEdit(scope.row.ID)" size="small"
                >编辑</el-button
              >
            </div>
            <el-button
              slot="reference"
              size="mini"
              icon="el-icon-edit"
              circle
            ></el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      layout="total, prev, pager, next"
      :page-size="query.pagesize"
      :current-page="query.pagenum"
      :total="total"
      @current-change="currentChange"
    >
    </el-pagination> -->
    <p style="color: #303133; font-size: 14px; margin-top: 7px;">
      共 {{ total }} 个媒体内容
    </p>
    <div style="height: 27px; width: 100%"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listloading: true,
      list: [],
      total: 0,
      date_picker: [],
      query: {
        id: '',
        pagesize: 15,
        pagenum: 1,
        update_date_f: '',
        update_date_l: '',
        not_publish: '0',
        type: '0',
        summary: ''
      }
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    goTag(id) {
      this.$store.state.tag.loadID = id
      this.$router.push('/tag').catch(err => err)
    },
    goFile(id) {
      this.$store.state.file.loadID = id
      this.$router.push('/file').catch(err => err)
    },
    goLetter(id) {
      this.$store.state.letter.loadID = id
      this.$router.push('/letter').catch(err => err)
    },
    addMedia() {
      this.$store.state.contiune = '/media/add'
      this.$router.push('/media/add').catch(err => err)
    },
    doPublish(b, id) {
      if (b) {
        this.$msgbox('确定发布吗？', '光影日记', {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await this.$http.put('media/p/' + id)
            if (res.status != 200) return this.$message(res.message)
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.loadList()
          })
          .catch(() => {})
      } else {
        this.$msgbox('确定撤销发布吗？', '光影日记', {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await this.$http.put('media/r/' + id)
            if (res.status != 200) return this.$message(res.message)
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.loadList()
          })
          .catch(() => {})
      }
    },
    doDel(id) {
      this.$msgbox('确定删除吗？', '光影日记', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.delete('media/' + id)
          if (res.status != 200) return this.$message(res.message)
          this.$http.delete('file/oid/' + id)
          if (res.status != 200) return this.$message(res.message)
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.loadList()
        })
        .catch(() => {})
    },
    doEdit(id) {
      this.$store.commit('reSetMedia')
      this.$store.state.media.media.id = id
      this.$store.state.contiune = '/media/edit'
      this.$router.push('/media/edit').catch(err => err)
    },
    currentChange(val) {
      this.$store.state.media.pagenum = val
      this.loadList()
    },
    doSch() {
      this.$store.state.media.pagenum = 1
      this.loadList()
    },
    async loadList() {
      this.listloading = true
      this.query.pagenum = this.$store.state.media.pagenum
      if (this.date_picker == null) {
        this.query.update_date_f = ''
        this.query.update_date_l = ''
      } else if (this.date_picker.length == 0) {
        this.query.update_date_f = ''
        this.query.update_date_l = ''
      } else {
        this.query.update_date_f = this.date_picker[0]
        this.query.update_date_l = this.date_picker[1]
      }
      var load_id = this.$store.state.media.loadID
      this.$store.state.media.loadID = ''
      if (load_id != '') this.query.id = load_id

      // 取消分页
      this.query.pagesize = -1
      this, (this.query.pagenum = -1)

      const { data: res } = await this.$http.get('media', {
        params: this.query
      })
      if (res.status != 200) return this.$message(res.message)
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].UpdatedAt = this.formatDate(
          res.data[i].UpdatedAt,
          '暂无',
          true
        )
        res.data[i].publish_dt = this.formatDate(
          res.data[i].publish_dt,
          '未发布',
          true
        )
        if (res.data[i].title.length > 29) {
          res.data[i].title = res.data[i].title.substr(0, 27) + '...'
        }
        if (res.data[i].summary.length > 29) {
          res.data[i].summary = res.data[i].summary.substr(0, 27) + '...'
        }
        res.data[i].taglist = []
        this.getTag(res.data[i].ID)
      }
      this.list = res.data
      this.total = res.total
      this.listloading = false
    },
    async getTag(id) {
      this.$http
        .get('t', {
          params: {
            objective_id: id
          }
        })
        .then(res => {
          this.list.forEach(el => {
            if (el.ID == id) {
              el.taglist = res.data.data
            }
          })
        })
    }
  }
}
</script>

<style scoped>
.media {
  width: 1070px;
  margin: 0 auto;
}
.selectlist {
  height: 50px;
  width: 100%;
  padding: 0 67px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.schbut {
  height: 32px;
  margin-top: 9px;
  line-height: 32px;
}
</style>
