<template>
  <div class="app">
    <div class="ad">
      <input
        id="log"
        v-model="log"
        name="log"
        type="password"
        placeholder="授权码"
        @click="reset()"
        v-on:keyup.enter="sub()"
      />
      <div @click="sub()" id="sub">{{ currentTime + ' ' + next }}</div>
    </div>
    <div id="logo"><img src="../assets/a_b.png" @click="gohome()" /></div>
    <img v-show="captchaImgShow" class="captcha" :src="captchaImgUrl" />
  </div>
</template>

<script>
import { Url } from '../plugins/axios.js'
export default {
  data() {
    return {
      url: Url,
      log: '',
      next: '',
      captchaImgShow: false,
      captchaImgUrl: '',
      captchaImgID: '',
      timer: '',
      currentTime: this.getTime()
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  created() {
    this.next = '下一步'
    this.getCaptcha()

    var _this = this
    this.timer = setInterval(function() {
      _this.currentTime = _this.getTime()
    }, 1000)
  },
  methods: {
    getTime() {
      let d = new Date()
      return d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    },
    async getCaptcha() {
      const { data: res } = await this.$http.get('captcha')
      if (res.status != 200) return (this.next = '网络错误')
      this.captchaImgUrl = this.url + res.captcha_img
      this.captchaImgID = res.captcha_id
    },
    reset() {
      this.captchaImgShow = true
      this.next = '下一步'
    },
    gohome() {
      window.location.href = 'https://blog.lpqo.cn'
    },
    async sub() {
      if (this.log.length == 0) return (this.next = '授权码为空')
      const { data: res } = await this.$http.post(
        'minnn', {
        password: this.log.substring(6, this.log.length),
        captcha_id: this.captchaImgID,
        captcha_str: this.log.substring(0, 6),
      })

      if (res.status !== 200) {
        this.next = res.message
        this.getCaptcha()
        return
      }
      window.sessionStorage.setItem('token', res.token)
      this.$router.push('/info').catch(err => err)
    }
  }
}
</script>

<style scoped>
#logo img,
#sub {
  cursor: pointer;
  user-select: none;
}

.app {
  width: 500px;
  margin: 0 auto;
  padding-top: 100px;
}

.ad {
  float: right;
  width: 55%;
  height: 80px;
  margin-top: 50px;
}

#log {
  width: 90%;
  border: none;
  outline: none;
  border-bottom: solid 1px #777;
  border-radius: 0px;
  height: 30px;
  font-size: 16px;
  display: block;
  margin: 0 auto;
}

#sub {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  height: 25px;
  line-height: 23px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #777;
}

#sub:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

#logo {
  margin-top: 50px;
  float: left;
  width: 45%;
  height: 80px;
  border-right: 1px solid #777;
}

#logo img {
  width: 90%;
  display: block;
  margin: 15px auto;
}

.captcha {
  display: block;
  margin: 147px auto;
}
</style>
